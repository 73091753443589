import React, {useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import checkToken from "./utils/common";
import checkClientToken from "./utils/common";
import Loader from "./pages/Loader"


const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const ForgetPassword = React.lazy(() => import("./pages/ForgetPassword"));

const ClientLogin = React.lazy(() => import("./pages/ClientLogin"));
const ClientHome = React.lazy(() => import("./pages/ClientHome"));
const ClientQR = React.lazy(() => import("./pages/ClientQR"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const VM = React.lazy(() => import("./pages/VM"));
const User = React.lazy(() => import("./pages/User"));
const Merchant = React.lazy(() => import("./pages/Merchant"));
const Device = React.lazy(() => import("./pages/Device"));
const UserDevice = React.lazy(() => import("./pages/UserDevice"));
const TestPage = React.lazy(() => import("./pages/TestPage"));
const AdminTicket = React.lazy(() => import("./pages/AdminTicket"));
const MonthlyReport = React.lazy(() => import("./pages/MonthlyReport"));
const Payment = React.lazy(() => import("./pages/Payment"));



const Cluster = React.lazy(() => import("./pages/Cluster"));
const Notifications = React.lazy(() => import("./pages/Notifications"));
const UserActions = React.lazy(() => import("./pages/UserActions"));
const HealthCheck = React.lazy(() => import("./pages/HealthCheck"));
const ServerError = React.lazy(() => import("./pages/ServerError"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const UserMetaProfileMgt = React.lazy(() => import("./pages/UserMetaProfileMgt"));

const TicketAccess = React.lazy(() => import("./pages/TicketAccess"));
const TicketAuth = React.lazy(() => import("./pages/TicketAuth"));
const QRScanPage = React.lazy(() => import("./pages/QRScanPage"));
const MerchantScannerLoginPage = React.lazy(() => import("./pages/MerchantScannerLoginPage"));






const ProjectRoutes = () => {

  const isLoggedIn = checkToken();
  const isClientLoggedIn = checkClientToken();
  

  return (
    <React.Suspense>
      <Router>
        <RouteLoader></RouteLoader>
        <Routes>
			
          <Route path="/login" element={<Login />} />
		  <Route path="/register" element={<Register />} />
		  <Route path="/forgetpassword" element={<ForgetPassword />} />
		  
		  
		  
          <Route
            path="/"
            element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="/usermetaprofilemgt"
            element={isLoggedIn ? <UserMetaProfileMgt /> : <Navigate to="/login" />}
          />
		  
          <Route
            path="/user"
            element={isLoggedIn ? <User /> : <Navigate to="/login" />}
          />
          <Route
            path="/user/create"
            element={isLoggedIn ? <User /> : <Navigate to="/login" />}
          />
          <Route
            path="/user/update/:searchUserId"
            element={isLoggedIn ? <User /> : <Navigate to="/login" />}
          />
          <Route
            path="/userdevice/:searchUserId"
            element={isLoggedIn ? <UserDevice /> : <Navigate to="/login" />}
          />


		  {  /*
          <Route
            path="/couponuser"
            element={isLoggedIn ? <User /> : <Navigate to="/login" />}
          />
          <Route
            path="/couponuser/create"
            element={isLoggedIn ? <User /> : <Navigate to="/login" />}
          />
          <Route
            path="/couponuser/update/:searchUserId"
            element={isLoggedIn ? <User /> : <Navigate to="/login" />}
          />
          <Route
            path="/couponuserdevice/:searchUserId"
            element={isLoggedIn ? <UserDevice /> : <Navigate to="/login" />}
          />
		  */
		  }




          <Route
            path="/merchant"
            element={isLoggedIn ? <Merchant /> : <Navigate to="/login" />}
          />
          <Route
            path="/merchant/create"
            element={isLoggedIn ? <Merchant /> : <Navigate to="/login" />}
          />
          <Route
            path="/merchant/update/:searchMerchantId"
            element={isLoggedIn ? <Merchant /> : <Navigate to="/login" />}
          />
          <Route
            path="/merchant/updatemsgsvr/:searchMerchantId"
            element={isLoggedIn ? <Merchant /> : <Navigate to="/login" />}
          />


          <Route
            path="/device"
            element={isLoggedIn ? <Device /> : <Navigate to="/login" />}
          />
          <Route
            path="/device/create"
            element={isLoggedIn ? <Device /> : <Navigate to="/login" />}
          />
          <Route
            path="/device/update/:deviceId"
            element={isLoggedIn ? <Device /> : <Navigate to="/login" />}
          />


          <Route
            path="/couponevent"
            element={isLoggedIn ? <Device /> : <Navigate to="/login" />}
          />
          <Route
            path="/couponevent/create"
            element={isLoggedIn ? <Device /> : <Navigate to="/login" />}
          />
          <Route
            path="/couponevent/update/:deviceId"
            element={isLoggedIn ? <Device /> : <Navigate to="/login" />}
          />


          <Route
            path="/testpage"
            element={isLoggedIn ? <TestPage /> : <Navigate to="/login" />}
          />
		  
          <Route
            path="/ticket"
            element={isLoggedIn ? <AdminTicket /> : <Navigate to="/login" />}
          />
		  
          <Route
            path="/normalticket"
            element={isLoggedIn ? <AdminTicket /> : <Navigate to="/login" />}
          />

          <Route
            path="/citypassticket"
            element={isLoggedIn ? <AdminTicket /> : <Navigate to="/login" />}
          />
		  
		  <Route
            path="/citypassticketevents/:orderEmail"
            element={isLoggedIn ? <AdminTicket /> : <Navigate to="/login" />}
          />
		  

          <Route
            path="/monthlyreport"
            element={isLoggedIn ? <MonthlyReport /> : <Navigate to="/login" />}
          />

          <Route
            path="/dailyreport"
            element={isLoggedIn ? <MonthlyReport /> : <Navigate to="/login" />}
          />


          <Route
            path="/payment"
            element={isLoggedIn ? <Payment /> : <Navigate to="/login" />}
          />

          <Route
            path="/payment/history"
            element={isLoggedIn ? <Payment /> : <Navigate to="/login" />}
          />


          <Route
            path="/payment/payment_success"
            element={isLoggedIn ? <Payment /> : <Navigate to="/login" />}
          />
		  
		  
		  <Route
            path="/payment/payment_fail"
            element={isLoggedIn ? <Payment /> : <Navigate to="/login" />}
          />
		  
          <Route
            path="/payment/history"
            element={isLoggedIn ? <Payment /> : <Navigate to="/login" />}
          />
		  
          <Route
            path="/vm"
            element={isLoggedIn ? <VM /> : <Navigate to="/login" />}
          />
          <Route
            path="/vm/create"
            element={isLoggedIn ? <VM /> : <Navigate to="/login" />}
          />
          <Route
            path="/vm/update/:vmId"
            element={isLoggedIn ? <VM /> : <Navigate to="/login" />}
          />
          <Route
            path="/cluster"
            element={isLoggedIn ? <Cluster /> : <Navigate to="/login" />}
          />
          <Route
            path="/cluster/create"
            element={isLoggedIn ? <Cluster /> : <Navigate to="/login" />}
          />
          <Route
            path="/cluster/update/:clusterId"
            element={isLoggedIn ? <Cluster /> : <Navigate to="/login" />}
          />
          <Route
            path="/notifications"
            element={isLoggedIn ? <Notifications /> : <Navigate to="/login" />}
          />
          <Route
            path="/user-activity"
            element={isLoggedIn ? <UserActions /> : <Navigate to="/login" />}
          />
          <Route
            path="/healthcheck"
            element={<HealthCheck />}
          />
		  
		  <Route path="/clientlogin" element={<ClientLogin />} />
		  <Route
            path="/clienthome"
            element={isClientLoggedIn ? <ClientHome /> : <Navigate to="/clientlogin" />}
          />
		  <Route
            path="/clientqr"
            element={isClientLoggedIn ? <ClientQR /> : <Navigate to="/clientlogin" />}
          />
		  
		  
		  <Route path="/ticketAuth" element={<TicketAuth />} />
		  <Route path="/ticketAccess" element={<TicketAccess />} />
		  
		  
		  <Route path="/qrScanPage" element={<QRScanPage />} />
		  <Route path="/merchantScannerLogin" element={<MerchantScannerLoginPage />} />
		  
          <Route path="/500" element={<ServerError />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};

export default ProjectRoutes;


const RouteLoader = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 300);

    return () => clearTimeout(loadingTimeout);
  }, []);

  return isLoading && (
    <Loader></Loader>
  )
};
